@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
    --color-background: #fff;
    --color-secundary-background: #262f66;
    --color-primary-lighter: #5E87FF;
    --color-primary-light: #5579E6;
    --color-primary: #232d91;
    --color-primary-dark: #181f69;
    --color-primary-darker: #6842C2;
    --color-secundary: #04D361;
    --color-secundary-dark: #04BF58;
    --color-title-in-primary: #FFFFFF;
    --color-text-in-primary: #D4C2FF;
    --color-text-title: #32264D;
    --color-text-complement: #323636;
    --color-border-base: rgba(68, 68, 68, 0.6);
    --color-text-base: #6A6180;
    --color-line-in-white: #E6E6F0;
    --color-input-background: #F8F8FC;
    --color-button-text: #FFFFFF;
    --color-box-base: #FFFFFF;
    --color-box-footer: #FAFAFC;
    --color-text-description: rgba(68, 68, 68, 0.8);
    --color-text-subdescription: rgba(68, 68, 68, 0.6);
    --color-text-description-opacity: rgba(68, 68, 68, 0.4);
    --color-button-hover: #FF8E40;
    --color-text-error: #d21a07;
    --color-text-success: #00bc22;
    
    --color-white: #fff;
    --color-text-primary: #656263;
    --color-subtitle-primary: #D68E70;
    --color-input-title-primary: #1F1F1F;
    --color-input-placeholder-primary: #BDBCBC;
    --font-button-text-primary: 'Noto Sans', sans-serif;
    --font-text-primary: "Calibri", "Roboto", sans-serif;
}

.image-perfil-circle {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
}

.photo-profile {
    width: 140px;
    height: 140px;
    object-fit: cover;
    border-radius: 50%;
    background-color: #666666;
}

.row {
    display: flex;
}

.row-all-center {
    width: 100%;
    display: flex;
    align-items: center;
}

.width-privacy-policy {
    width: 100%;
}

.width-privacy-policy p {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 20px;
}

.width-privacy-policy p a {
    text-decoration: none;
}

.width-privacy-policy p a:hover{
    text-decoration: underline;
}

.btn-icon-imh-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: #ffffff;
    font-size: 20px;
    border-radius: 10px;
    box-shadow: 0 1px 3px 0 #20212447;
    cursor: pointer;
}

.row-center {
    display: flex;
    align-items: center;
}

.row-center-width {
    width: 100%;
    display: flex;
    align-items: center;
}

.collum-center-width {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.link-login {
    color: #D68E70;
    text-decoration: none;
}

.row-width-between {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.width-all {
    width: 100%;
}

.row-width-end {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.div-box-loading {
    width: 100%;
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 1400px) {
    .cont-size-color-product {
        display: grid;
        grid-template-columns: 4fr !important;
    }
}